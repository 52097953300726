import React, { useEffect } from 'react';

const DynamicsMarketingForm = () => {
  const loadScript = (src, id) => {
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve(`Script ${id} already loaded.`);
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => resolve(`Script ${id} loaded successfully.`);
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const formLoaderScriptUrl = 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js';

    loadScript(formLoaderScriptUrl, 'form-loader-script')
      .then((message) => console.log(message))
      .catch((err) => console.error(err));

    // Set document language
    document.documentElement.lang = navigator.language;
  }, []);

  return (
    <div>
      <div
        data-form-id='432e0451-b7fa-4f34-bdbe-bd16d6a34eab'
        data-form-api-url='https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/eventmanagement'
        data-cached-form-url='https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/432e0451-b7fa-4f34-bdbe-bd16d6a34eab'>
      </div>
    </div>
  );
};

export default DynamicsMarketingForm;
